import React from 'react';
import Typography from '@mui/material/Typography';
import '@fontsource/roboto/300.css';
import {ReactSVG} from 'react-svg';
import SvgLogo from './images/logo.svg';

export const SidebarHeader = ({ children }) => {
  return (<div>
        <center>
        <ReactSVG src={SvgLogo}beforeInjection={(svg) => {
          svg.style.width = "100%";
          svg.style.height = "150px";
          svg.style.padding = "20px"
          }} />
          <hr style={{width: "60%",height: '4px',background: "#ffffff",border: "none"}}></hr>
        <Typography variant="h2" fontWeight={700} color="#fffff">
          Pasto
        </Typography>
        <hr style={{width: "60%",height: '4px',background: "#ffffff",border: "none"}}></hr>
      </center>
    </div>

  );
};