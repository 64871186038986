import React from 'react';
import { ProSidebarProvider } from 'react-pro-sidebar';
import SideBar from './Sidebar';
import {
  BrowserRouter as Router, 
  Routes,
  Route
} from 'react-router-dom';

import About from './pages/about';
import Contact from './pages/contact';
import Home from './pages/home';
import Machines from './pages/machines';
import PastomaUno from './pages/pastoma_uno';

import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  let width = '250px';  
  return  <div style={{ display: 'flex', height: '100vh'}}>
  <Router>
<ProSidebarProvider>
  <SideBar width={width}> </SideBar> 
</ProSidebarProvider>
<main>
  <div style={{ padding: '16px 24px', color: '#44596e' }}>
      <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/About" element={<About />} />
          <Route path="/PastomaUno" element={<PastomaUno />} />
          <Route path="/Machines" element={<Machines />} />
          <Route path="/Contact" element={<Contact />} />
        </Routes>
        </div>
        </main>
        
    </Router>
    </div>
}

export default App;
