import React from 'react'
import { Sidebar, Menu, MenuItem, SubMenu,menuClasses } from 'react-pro-sidebar';
import { Link } from 'react-router-dom';

import { SidebarHeader } from './Sidebarheader';
import { SidebarFooter } from './Sidebarfooter';

const themes = {
    sidebar: {
    backgroundColor: '#0b2948',
    color: '#ffffff',
    },
    menu: {
    menuContent: '#082440',
    icon: '#59d0ff',
    hover: {
        backgroundColor: '#0e3052',
        color: '#b6c8d9',
    },
    active: {
        backgroundColor: '#13395e',
        color: '#ffffff',
    },
    disabled: {
        color: '#3e5e7e',
    },
    },
  };
  

export default function SideBar({width}){

    const menuItemStyles = {
        root: {
          fontSize: '16px',
          fontWeight: 400,
        },
        icon: {
          color: themes["menu"].icon,
        },
        SubMenuExpandIcon: {
          color: '#ffffff',
        },
        subMenuContent: {
          backgroundColor: themes["menu"].menuContent,
        },
        button: {
          [`&.${menuClasses.active}`]: {
            backgroundColor: themes["menu"].active.backgroundColor,
            color: themes["menu"].active.color,
          },
          [`&.${menuClasses.disabled}`]: {
            color: themes["menu"].disabled.color,
          },
          '&:hover': {
            backgroundColor: themes["menu"].hover.backgroundColor,
            color: themes["menu"].hover.color,
          },
        },
        label: ({ open }) => ({
          fontWeight: open ? 600 : undefined,
        }),
      };


    return (<Sidebar width={width} 
        backgroundColor={themes["sidebar"].backgroundColor}
        rootStyles={{
            color: themes["sidebar"].color,
          }}>
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
    
    <SidebarHeader style={{ marginBottom: '24px', marginTop: '16px' }}> </SidebarHeader>
    
    <Menu menuItemStyles={menuItemStyles}>
    <MenuItem component={<Link to="/" />}>Home</MenuItem>
    <MenuItem component={<Link to="/About" />}>About</MenuItem>
      <SubMenu label="Projects">
      <SubMenu component={<Link to="/PastomaUno" />} label="Pastoma Uno">
      <MenuItem> Overview </MenuItem>
      <MenuItem> CAD </MenuItem>
      <MenuItem> Manufacturing </MenuItem>
      <MenuItem> App </MenuItem>
      <MenuItem> Firmware </MenuItem>
        </SubMenu>
        <MenuItem component={<Link to="/MotoDisplay" />}> Motorcycle Display </MenuItem>
        <MenuItem component={<Link to="/Cnc" />}> CNC Mill </MenuItem>
      </SubMenu>
      <SubMenu component={<Link to="/Machines" />} label="Machines">
      <MenuItem> Mill </MenuItem>
      <MenuItem> Lathe </MenuItem>
      <MenuItem> 3D-Printer </MenuItem>
      <MenuItem> Welder </MenuItem>
      <MenuItem> Bandsaw </MenuItem>
      <MenuItem> CNC </MenuItem>
      </SubMenu>
      <MenuItem component={<Link to="/Contact" />}> Contact </MenuItem>
    </Menu>
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
    </div>
    <SidebarFooter/>
    
    </div>
    
  </Sidebar>);
}