import React from 'react';
import '@fontsource/roboto/300.css';
import IconButton  from '@mui/material/Button';
import {Tooltip}  from '@mui/material';
import { GitHub, Instagram,Mail } from '@mui/icons-material';


export const SidebarFooter = ({ children}) => {
  return (<center>
  <div style={{padding:"24px 0px"}}>
    
  <Tooltip title="GitHub">
            <IconButton onClick={() =>  window.open('https://github.com/stooppas')}>
                <GitHub color="#ffffff"/>
            </IconButton >
        </Tooltip>
        <Tooltip title="Instagram">
            <IconButton  onClick={() =>  window.open('https://www.instagram.com/pamisto/')}>
                <Instagram/>
            </IconButton >
        </Tooltip>
        <Tooltip title="Mail">
            <IconButton  onClick={() =>  window.open('mailto:pascal.stoop@pasto.ch')}>
                <Mail/>
            </IconButton >
        </Tooltip>
        </div>
        </center>
  );
};